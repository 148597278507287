import './Loading.css';

const Loading = () => {
  return (
    <div className='loading-container'>
      <div className='loading-dot' />
      <div className='loading-dot' />
      <div className='loading-dot' />
    </div>
  );
};

export default Loading;
